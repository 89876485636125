<template>
    <div class="account-activities-table">
        <div class="searchAndAdd">
            <InputUnit title="" type="select" :selectOptions="categoryOptions" v-model="category" />
            <button class="blue" @click="openModal()">Refresh</button>
        </div>
        <table>
            <tr class="header">
                <th>User</th>
                <th>RefID</th>
                <th>Datetime</th>
                <th>Symbol</th>
                <th>Direction</th>
                <th>Volume</th>
                <th>Price</th>
                <th>Flag</th>
            </tr>
            <tr>
                <td>Crypto Allen<h6>@username</h6></td>
                <td>4272491709</td>
                <td>2022/08/18 13:27:00</td>
                <td>binance.bnb_usdt.spot.na</td>
                <td>Buy Open</td>
                <td>0.0500</td>
                <td>309.5023</td>
                <td>binance.bnb_usdt.spot.na</td>
            </tr>
        </table>
    </div>
</template>

<script>
import InputUnit from '@/components/InputUnit';
export default {
    name: 'AccountActivitiesTable',
    data(){
        return {
            categoryOptions: [{
                title: 'All',
                value: 'all'
            }],
            category: 'all'
        }
    },
    methods: {
    },
    components: {
        InputUnit,
    },
    mounted() {
    },
}
</script>