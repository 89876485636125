<template>
    <div id="chartRef" class="tradingview-widget-container">
        <div id="tradingview_a7978" :style="`height: ${height};`"></div>
        <div class="tradingview-widget-copyright" style="display: none;"><a href="https://tw.tradingview.com/symbols/BTCUSDT/?exchange=BINANCE" rel="noopener" target="_blank"><span class="blue-text">BTCUSDT圖表</span></a>由TradingView提供</div>
    </div>
</template>

<script>
// @ is an alias to /src
/*
import Switcher from '@/components/Switcher';
import InputUnit from '@/components/InputUnit';
import DashboardEquitiyValue from '@/components/DashboardEquitiyValue';
import APYStats from '@/components/APYStats';
import ExchangeAccountsTable from '@/components/ExchangeAccountsTable';
import UsersTable from '@/components/UsersTable';
import Switches from 'vue-switches';
*/

export default {
    name: 'TradingView',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
        }
    },
    props: {
        height: {
            type: String,
            default: '400px'
        },
        symbol: {
            type: String,
            default: 'BINANCE:USDT'
        }
    },
    methods: {
        runChart(){
            new window.TradingView.widget(
                {
                    "autosize": true,
                    "symbol": this.symbol,
                    "interval": "D",
                    "timezone": "Etc/UTC",
                    "theme": "light",
                    "style": "1",
                    "locale": "zh_TW",
                    "toolbar_bg": "#f1f3f6",
                    "enable_publishing": false,
                    "allow_symbol_change": true,
                    "container_id": "tradingview_a7978"
                }
            );
        },
        initChart(){
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/tv.js";
            script.async = true;
            script.onload = this.runChart;
            document.getElementById('chartRef').appendChild(script);
        }
    },
    mounted() {
        this.initChart()
    },
}
</script>
