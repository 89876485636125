<template>
    <div id="chartRef" class="db-container dashboard-pro-exchange-detail">
        <div class="db-modal edit-api">
            <div class="content">
                <div class="wrapper">
                    <h3>Edit API Setting</h3>
                    <p>Enter your new api setting below.</p>
                    <div class="form">
                        <InputUnit class="mobile-full" title="API Key" v-model="apiSetting.key" type="text" placeholder="API Key..." :hasBtn="false" :disabled="false"/>
                        <InputUnit class="mobile-full" title="API Secret" v-model="apiSetting.secret" type="text" placeholder="API Secret..." :hasBtn="false" :disabled="false"/>
                        <InputUnit class="full" title="API Passphrase" v-model="apiSetting.passphrase" type="text" placeholder="API Passphrase..." :hasBtn="false" :disabled="false"/>
                    </div>
                </div>
                <div class="btn-container">
                    <button class="blue" @click="closeEditAPI()">Cancel</button>
                    <button class="light" @click="handleEditAPI()">Save</button>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="db-content">
            <h2 class="title">
                Exchange Account Details
            </h2>
            <a class="backLink" href="/dashboard-pro/exchange">Back</a>
            <div class="info">
                <div class="title"><img :src="loadExchangeIcon(info.xchg)" alt="" class="icon"><h4>{{ info.account_name }}</h4></div>
                <div class="subtitle">
                    <p>Exchange:<span>{{ info.xchg }}</span></p>
                    <p>Portfolio:<span>{{ info.product }}</span></p>
                    <p>Standard:<span>{{ info.standard.toUpperCase() }}</span></p>
                </div>
                <div class="tab-container">
                    <div class="tabs">
                        <div :key="ind" v-for="(ele, ind) in tabOptions" :class="`tab${ele.value == selectedTab ? ' active' : ''}`" @click="selectedTab=ele.value">{{ ele.title }}</div>
                    </div>
                </div>
            </div>
            <div v-if="selectedTab == 'trad'" class="trade-filters-container">
                <div class="pair">
                    <span :class="tradeType == 'marginCross' ? 'selected': ''" @click="tradeType = 'marginCross'">Margin Cross</span>
                    <span :class="tradeType == 'marginIsolated' ? 'selected': ''" @click="tradeType = 'marginIsolated'">Margin Isolated</span>
                    <span :class="tradeType == 'spot' ? 'selected': ''" @click="tradeType = 'spot'">Spot</span>
                    <span :class="tradeType == 'usdMarginedContract' ? 'selected': ''" @click="tradeType = 'usdMarginedContract'">USD-Margined Contract</span>
                    <span :class="tradeType == 'coinMarginedContract' ? 'selected': ''" @click="tradeType = 'coinMarginedContract'">Coin-Margined Contract</span>
                </div>
            </div>
            <div v-if="selectedTab == 'overview'" class="content">
                <div class="large chart">
                    <h6>Total Equity</h6>
                    <h3><img :src="loadIcon('USDT')" alt="" class="icon">{{ this.equityData.length ? (this.equityData.slice(-1)[0].data) : 0 | currency }}<h5 v-if="false" class="increase">+10.23%</h5></h3>
                    <p>Total Equity</p>
                    <div>
                        <DashboardEquitiyValue :records="this.equityData" :height="200" :styles="myStyles"/>
                    </div>
                </div>
                <div class="small vertical-chart">
                    <h6>Trading Volume Stats</h6>
                    <h3><img :src="loadIcon('USDT')" alt="" class="icon">${{ volumeData.length != 0 ? volumeData.reduce((acc, cur) => acc + cur.total, 0) : 0 | currency }}</h3>
                    <p>Total Trading Volume</p>
                    <div class="chart">
                        <div :key="ind" v-for="(ele, ind) in volumeData">
                            <div class="first" :style="`height: ${ele.spot * 100}%;`"></div>
                            <div class="second" :style="`height: ${ele.contract * 100}%;`"></div>
                        </div>
                    </div>
                    <div class="description">
                        <p>Spot</p>
                        <p>Contract</p>
                    </div>
                </div>
            </div>
            <div v-if="selectedTab == 'trade'" class="content">
                <div class="xxlarge">
                    <TradingView :symbol="`${this.info.standard.toUpperCase()}`"/>
                </div>
            </div>
            <div v-if="selectedTab == 'settings'" class="setting-panel">
                <div v-if="false" class="controll">
                    <div :class="settingType == 0 ? 'active' : ''" @click="settingType = 0">Basic Info</div>
                    <div :class="settingType == 1 ? 'active' : ''" @click="settingType = 1">Advanced Settings</div>
                </div>
                <div v-if="true" class="panel">
                    <h3>API Settings</h3>
                    <div class="form">
                        <a class="edit-api" href="#" @click="openEditAPI()">Edit API Settings...</a>
                    </div>
                    <h3>Symbol Settings</h3>
                    <div class="form">
                        <div class="input-unit full">
                            <h4 class="input-title">Symbols</h4>
                            <vSelect class="full select-symbols" multiple :reduce="(option) => option.code" :options="symbolsOptions" v-model="symbols" placeholder="Select symbols..."></vSelect>
                        </div>
                        <InputUnit v-if="false" class="full" title="Batch Add Symbols" placeholder="Batch enter symbols, separated by commas; enter 'clear all' to remove all symbols." type="textarea" />
                    </div>
                    <InputUnit title="Account Mode" type="select" :selectOptions="acModeOptions" v-model="info.account_mode"/>

                    <InputUnit title="Extra Info" type="textarea" v-model="info.extra_info"/>
                    
                    <div class="btn-container">
                        <button class="blue" @click="handleChangeExchgDetail()">Save</button>
                    </div>
                </div>
                <div v-if="false" class="panel">
                    <h3>Advanced Settings</h3>
                    <div class="form">
                        <InputUnit class="mobile-full" title="API Key" type="text" placeholder="API Key..." :hasBtn="false" :disabled="false"/>
                        <InputUnit class="mobile-full" title="API Secret" type="text" placeholder="API Secret..." :hasBtn="false" :disabled="false"/>
                        <div class="switches">
                            <Switches color="blue" v-model="portfolio.recording"></Switches>Enabled recording net value
                        </div>
                        <InputUnit class="full" title="Cache" type="textarea" />
                    </div>
                    <div class="btn-container">
                        <button class="blue">Save</button>
                    </div>
                    <h3 class="delete-headline">Delete Portfolio</h3>
                    <p class="delete-notice">Delete action will remove all portfolio data, please operate cauciously.</p>
                    <div class="btn-container">
                        <button class="light">Delete</button>
                    </div>
                </div>
            </div>
            <div v-if="selectedTab == 'activities' && false" class="activities-panel">
                <AccountActivitiesTable />
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import DashboardEquitiyValue from '@/components/DashboardEquitiyValue';
import TradingView from '@/components/TradingView';
import InputUnit from '@/components/InputUnit';
import AccountActivitiesTable from '@/components/AccountActivitiesTable';
import vSelect from "vue-select";
import {encrypt} from '@/utils/Encryption.js';
import {apiAccountInfo, apiAccountHist, apiQuerySymbols, apiCreateAccount} from '@/apis/pro/accounts.js'
/*
import Switcher from '@/components/Switcher';
import InputUnit from '@/components/InputUnit';
import DashboardEquitiyValue from '@/components/DashboardEquitiyValue';
import APYStats from '@/components/APYStats';
import ExchangeAccountsTable from '@/components/ExchangeAccountsTable';
import UsersTable from '@/components/UsersTable';
import Switches from 'vue-switches';
*/

export default {
    name: 'ExchangeDetail',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            periodOptions: [{
                title: '7 Days',
                value: '7days'
            }, {
                title: '30 Days',
                value: '30days'
            }, {
                title: '90 Days',
                value: '90days'
            }, {
                title: 'All',
                value: 'all'
            }],
            period: '7days',
            searchPortfolio: '',
            acModeOptions: [{
                title: 'Normal',
                value: '0'
            },{
                title: 'Omni',
                value: '1'
            }],
            tabOptions: [{
                title: 'Overview',
                value: 'overview',
            },{
                title: 'Trade',
                value: 'trade',
            },{
                title: 'Settings',
                value: 'settings',
            }],
            portfolio: {
                status: 'operating',
                recording: true
            },
            symbolsOptions: [],
            symbols: [],
            statusOptions: [{
                title: "Operating",
                value: 'operating',
            }],
            selectedTab: 'overview',
            tradeType: 'marginCross',
            settingType: 0, //0:basuc 1:advance
            info: {},
            loading: true,
            hist: [],
            loadingHist: true,
            apiSetting: {
                key: '',
                secret: '',
                passphrase: ''
            }
        }
    },
    components: {
        DashboardEquitiyValue,
        TradingView,
        InputUnit,
        vSelect,
        AccountActivitiesTable,
    },
    methods: {
        loadIcon(name = ""){
            if(name == "GBP")return `https://static.bitfinex.com/images/icons/GBP-alt.svg`;
            try {
                return require(`@/assets/images/crypto-logos/${name.replace('-PERP', '')}.png`);
            } catch (e) {
                // return require(`@/assets/images/crypto-default.png`);
                return `https://static.bitfinex.com/images/icons/${name.replace('-PERP', '')}-alt.svg`;
            }
        },
        loadInfo(){
            apiAccountInfo({
                account_name: this.$route.params.id
            }).then(res => {
                if(res.data.result){
                    this.info = res.data.data
                    this.loadSymbols(this.info.xchg)
                    this.symbols = this.info.symbols
                    this.loading = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        loadHist(){
            apiAccountHist({
                account: this.$route.params.id
            }).then(res => {
                if(res.data.result){
                    this.hist = res.data.data
                    this.loadingHist = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        loadExchangeIcon(name = ""){
            try {
                return require(`@/assets/images/exchange-logos/${name.toLowerCase()}.svg`);
            } catch (e) {
                return require(`@/assets/images/crypto-default.png`);
            }
        },
        loadSymbols(xchg){
            apiQuerySymbols({
                xchg: xchg
            }).then(res => {
                this.symbolsOptions = res.data.symbols.map(item => ({label: item, code: item}))
                /*
                if(res.data.result){
                    this.symbolsOptions = res.data.data.map(item => ({label: item, code: item}))
                    console.log(this.symbolsOptions)
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }*/
            })
        },
        closeEditAPI(){
            $('.db-modal.edit-api').fadeOut(300)
        },
        handleEditAPI(){
            if(this.apiSetting.key == ''){
                this.$toast.error("Please enter your account's API Key", {
                    timeout: 2000
                });
            }else if(this.apiSetting.secret == ''){
                this.$toast.error("Please enter your account's API Secret", {
                    timeout: 2000
                });
            }else{
                let submitForm = Object.assign({}, this.apiSetting)
                const origin = {
                    api_key: submitForm.key,
                    api_secret: submitForm.secret,
                    api_passphrase: submitForm.passphrase
                }
                const key = `${origin.api_key.slice(0, 5)}${origin.api_key.slice(-6, -1)}`
                const aes = encrypt(JSON.stringify(origin))
                const plength = parseInt(aes.length / 2)
                const secret = aes.slice(0, plength)
                const check_sum = aes.slice(plength, aes.length)
                submitForm.product = this.info.product
                submitForm.key = key
                submitForm.secret = secret
                submitForm.check_sum = check_sum
                submitForm.account_name = this.$route.params.id
                submitForm.update_key = true
                delete submitForm.passphrase
                apiCreateAccount(submitForm).then(res => {
                    if(res.data.result){
                        this.$toast.success('API Updated!', {
                            timeout: 2000
                        });
                        //location.reload()
                    }else{
                        this.$toast.error(res.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }
        },
        handleChangeExchgDetail(){
            let tmp = this.symbols.reduce((acc, cur) => `${acc},${cur}`, '').slice(1)
            apiCreateAccount({
                account_name: this.$route.params.id,
                product: this.info.product,
                symbols: tmp,
                account_mode: this.info.account_mode,
                extra_info: this.info.extra_info
            }).then(res => {
                if(res.data.result){
                    this.$toast.success('Symbols Updated!', {
                        timeout: 2000
                    });
                    location.reload()
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        openEditAPI(){
            $('.db-modal.edit-api').fadeIn(300).css('display', 'flex')
        }
    },
    mounted() {
        this.loadInfo()
        this.loadHist()
    },
    computed: {
        myStyles() {
          return {
            height: '100%',
            width: '100%',
            position: 'relative',
          };
        },
        equityData() {
            if(this.hist.length == 0)return []
            return this.hist.map(item => ({label: new Date(Number(item.ts)*1000), data: Object.keys(item.equity).reduce((acc, cur) => acc + item.equity[cur].in_total, 0)}))
        },
        volumeData() {
            let data = this.hist.filter(item => item.trading_volume).map(item => Object.assign({}, item.trading_volume))
            if(data.length == 0) return [];
            else if(data.length <= 7){
                data = data.map(item => ({
                    contract: item.contract ? item.contract : 0,
                    spot: item.spot ? item.spot : 0
                }))
                const max = data.reduce((pre, now) => pre > (now.contract + now.spot) ? pre : (now.contract + now.spot), 0)
                data = data.map(item => ({
                    contract: item.contract / max,
                    spot: item.spot / max,
                    total: item.contract + item.spot
                }))
                return data
            }
            let len = data.length
            let ticks = Math.floor(len / 7)
            data = data.reduce((acc, cur, ind) => {
                if(ind % ticks ==0 && (ind + ticks) <= data.length){
                    acc.push({
                        contract: cur.contract ? cur.contract : 0,
                        spot: cur.spot ? cur.spot : 0
                    })
                }else{
                    acc[acc.length - 1].contract += cur.contract ? cur.contract : 0
                    acc[acc.length - 1].spot += cur.spot ? cur.spot : 0
                }
                return acc
            }, [])
            const max = data.reduce((pre, now) => pre > (now.contract + now.spot) ? pre : (now.contract + now.spot), 0)
            data = data.map(item => ({
                contract: item.contract / max,
                spot: item.spot / max,
                total: item.contract + item.spot
            }))
            return data
        },
    },
}
</script>
